@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.qreaderr .App {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qreaderr .Button {
  color: white;
  background-color: blue;
  padding: 10px;
  width: 100px;
  font-weight: 800;
  letter-spacing: 2;
  cursor: pointer;
  border-radius: 10px;
  transform: all 1s cubic-bezier(0.42, 0, 0.17, 0.71);
  margin: 10px;
}

.qreaderr .Button:hover {
  background-color: red;
}

.qreaderr .dialog {
  padding: 20px;
  background: white;
  position: absolute;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 37, 37, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.qreaderr .dialog-content {
  background-color: white;
  margin: 0;
  width: 400px;
  height: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
}

.qreaderr .close {
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.qreaderr .close > button {
  height: 36px;
  width: 36px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.qreaderr .title {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 5px;
}

@keyframes anime {
  0% {
    font-size: 10px;
  }
  30% {
    font-size: 15px;
  }
  100% {
    font-size: 12px;
  }
}

.qreaderr .detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 40px;
  border-bottom: 1px dashed;
  animation:slidein 2s;
}
.qreaderr .detail-first-child {
  border-top: 1px dashed;
  margin-top: 20px;
}

.qreaderr .detail-header {
  font-weight: bold;
  font-size: 1rem;
}

.qreaderr .detail-content {
  font-size: 1rem;
  padding-left: 5px;
}

.green {
  color: rgb(153, 255, 0);
}
.qreaderr .description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
}
.qreaderr .red {
  color: red;
}

.qreaderr .message {
  font-size: 0.8rem;
}
.qreaderr .small {
  font-size: 0.9rem;
}


@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.qreaderr .errorMessage{
  display: flex;
  align-content: center;
  align-items: center;
}
@media(max-width: 768px){
    .dialogCustomQr{
        top: 88px;
        position: absolute;
        width: 80%;
    }
}
