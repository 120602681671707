
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-container{
  /* background: linear-gradient(0deg, #1877F2, #1877F2),
linear-gradient(0deg, #E7F1FF, #E7F1FF); */

}
input[type="text"],
input[type="email"],
input[type="password"] {
  -webkit-appearance: none;
}

.device-qr-code{
  width: 134px;
  height: 131px;
}

.ocr-video{
  object-fit: initial;
  width: 100%;
  height: 400px;
}
.ocr-main{
  overflow: scroll;
}
